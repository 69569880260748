<template>
  <div>
    <!-- 头部组件 -->
    <Header />

    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="1" />

    <!-- 本页面的模块 -->
    <div class="tradIndex">
      <TradHeader @inputText="Text" :text="inputText"/>
      <div class="findConten">
        <div class="contentLeft">
          <div>
            <div
              class="contentLeftHeader"
              v-show="inputText === '' ? false : true"
            >
              关于【{{ inputText }}】的查询结果：共有商标分类{{
                inputTextClass.length
              }}项：
            </div>
            <el-skeleton :rows="10" animated v-show="state05" />
            <!-- 商标分类列表 -->
            <div
              class="classificationList"
              v-show="bigClass === '' ? false : true"
            >
              <!-- 表标题 -->
              <div class="listTitle">商标分类：</div>
              <!-- 分类列表 -->
              <div class="listContent">
                <div
                    class="option"
                    v-for="(item, index) in bigClass"
                    :key="index"
                  >
                    <div class="listClose01">
                      <div
                        class="listClose"
                        v-show="beforeIndex.includes(index) ? true : false"
                      >
                        <img
                          src="../imger/tranClassGroup/cose.png"
                          alt="商标分类查询"
                          @click="close(index)"
                        />
                      </div>
                    </div>
                    <div
                      class="unSelected"
                      :style="{
                        borderColor: beforeIndex.includes(index)
                          ? '#4D89F1'
                          : '',
                        color: beforeIndex.includes(index) ? '#4D89F1' : '',
                      }"
                      @click="tranClass(index)"
                    >
                      {{10 > item.code ? '0'+item.code:item.code }}-{{ item.name }}
                    </div>
                  </div>
              </div>
            </div>
            <!-- 商标分类列表2 -->
            <div
              class="classificationList"
              v-show="bigClassSousuo === '' ? false : true"
            >
              <!-- 表标题 -->
              <div class="listTitle">商标分类：</div>
              <!-- 分类列表 -->
              <div class="listContent">
                <div
                    class="option"
                    v-for="(item, index) in bigClassSousuo"
                    :key="index"
                  >
                    <div class="listClose01">
                      <div
                        class="listClose"
                        v-show="beforeIndex.includes(index) ? true : false"
                      >
                        <img
                          src="../imger/tranClassGroup/cose.png"
                          alt="商标分类查询"
                          @click="close(index)"
                        />
                      </div>
                    </div>
                    <div
                      class="unSelected"
                      :style="{
                        borderColor: beforeIndex.includes(index)
                          ? '#4D89F1'
                          : item.status == 'true'
                          ? '#FF7E00'
                          : '',
                        color: beforeIndex.includes(index)
                          ? '#4D89F1'
                          : item.status == 'true'
                          ? '#FF7E00'
                          : '',
                      }"
                      @click="tranClass(index)"
                    >
                      {{10 > item.code ? '0'+item.code:item.code }}-{{ item.name }}
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <!-- 大类介绍 -->
          <div
            class="contentLeftTitle"
            v-for="(item, index) in classdata"
            :key="index"
          >
            <div
              class="contentLeftTitleHeader"
              @click="fn1(index + 1)"
              v-show="classdata === '' ? false : true"
            >
              <div
                class="contentLeftTitleHeaderLeft"
                :style="{ color: item.status == true ? '#4D89F1' : '' }"
              >
                <div class="lan"></div>
                <span class="word01">第{{ item.code }}类-{{ item.name }}</span>
                <span>共{{ item.group.length }}个群组</span>
              </div>
              <img
                class="contentLeftTitleHeaderRight"
                :style="{
                  transform:
                    start == index + 1 ? 'rotate(-90deg)' : 'rotate(0deg)',
                }"
                src="../imger/tranClassGroup/openIcon.png"
                alt="商标分类查询"
              />
            </div>
            <div
              class="contentLeftTitleContent"
              :style="{ display: start == index + 1 ? 'block' : 'none' }"
            >
              <div class="classdataContent" v-html="item.content"></div>
            </div>

            <div
              v-for="(itemChild, indexChild) in item.group"
              :key="indexChild"
              :style="{ display: start == index + 1 ? 'block' : 'none' }"
            >
              <!-- 群组分类 -->
              <div class="findlei" @click="fn2(indexChild + 1)">
                <div
                  :class="[tubiao == indexChild + 1 ? 'icon02' : 'icon01']"
                ></div>
                <p
                  class="findleititle"
                  :style="{
                    color: itemChild.status == 'true' ? '#4D89F1' : '',
                  }"
                >
                  {{ itemChild.code }}---{{ itemChild.name }}
                </p>
              </div>
              <!-- 小类分类 -->
              <div
                class="findcontent"
                :style="{
                  display: tubiao == indexChild + 1 ? 'block' : 'none',
                }"
              >
                <div class="findcontentBox">
                  <p
                    class="first"
                    v-for="(childItem, childIndex) in itemChild.term"
                    :style="{
                      color: childItem.status == 'true' ? '#4D89F1' : '',
                    }"
                    :key="childIndex"
                  >
                    {{ childItem.code }}--{{ childItem.name }}
                  </p>
                </div>
                <hr />
                <div class="remarks">
                  <p>备注：</p>
                  <div v-html="itemChild.content"></div>
                </div>
              </div>
            </div>
          </div>

          <el-skeleton :rows="6" animated v-show="state06" />
        </div>

        <div class="contentRight">
          <div class="header">精品商标推荐</div>

          <div class="box">
            <img
              class="picture"
              src="../imger/tranClassGroup/tupian.png"
              alt="商标分类查询"
            />
            <div class="onpicture">
              <div class="boxtitle">
                <p>灵典</p>
                <p>
                  第42类
                  <img src="../imger/tranClassGroup/tuceng0735.png" alt="商标分类查询" />
                </p>
              </div>
              <div class="boxprice">
                <p class="price">￥5000.00</p>
                <div class="anniu">立即沟通</div>
              </div>
            </div>
          </div>

          <div class="box">
            <img
              class="picture"
              src="../imger/tranClassGroup/tupian.png"
              alt="商标分类查询"
            />
            <div class="onpicture">
              <div class="boxtitle">
                <p>灵典</p>
                <p>
                  第42类
                  <img src="../imger/tranClassGroup/tuceng0735.png" alt="商标分类查询" />
                </p>
              </div>
              <div class="boxprice">
                <p class="price">￥5000.00</p>
                <div class="anniu">立即沟通</div>
              </div>
            </div>
          </div>

          <div class="box">
            <img
              class="picture"
              src="../imger/tranClassGroup/tupian.png"
              alt="商标分类查询"
            />
            <div class="onpicture">
              <div class="boxtitle">
                <p>灵典</p>
                <p>
                  第42类
                  <img src="../imger/tranClassGroup/tuceng0735.png" alt="商标分类查询" />
                </p>
              </div>
              <div class="boxprice">
                <p class="price">￥5000.00</p>
                <div class="anniu">立即沟通</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script >
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
// 接口引入
import {
  tranClassApi,
  transousuoClassApi,
  keyCode,
  transousuoClassGroupApi,
  tranClassGroupApi,
  newIP,
} from "../API/index";

export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
  },
  data() {
    return {
      start: "", //大类标题栏内容的显示
      tubiao: false,
      inputText: "",
      classdata: [], //大类里的数据or大类数据带有状态值
      groupdata: [], //群组里的数据or大类数据带有状态值
      // borderColor:0,
      groupdatacontent: [], //小项里的数据or小项数据带有状态值
      bigClassSousuo: "", //搜索查询之后的商标分类带有大类状态值
      inputTextClass: [], //搜索查询之后存放配对成功的大类的id
      optionID: "", //商标分类的ID
      inputID: "",
      bigClass: "", //查询到的所有大类
      beforeIndex: [],
      ip: "",
      state05: false, //加载动画
      state06: false, //加载动画
    };
  },
  async created() {
    await this.keyCode();
    await this.creat();
  },
  methods: {
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == null || duan == undefined) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    // 查询大类请求
    async creat() {
      this.state05 = true;
      let key = sessionStorage.getItem("key");
      const res = await tranClassApi({
        key: key,
      });
      this.bigClass = res.data.category;
      await sessionStorage.setItem("key", res.data.key);
      this.state05 = false;
    },

    // 点击选中的分类
    tranClass(index) {
      var img = document.querySelectorAll(".listClose");
      if (this.beforeIndex.includes(index)) {
        this.$message({
          message: "此大类已选择",
          type: "warning",
        });
      } else {
        img[index].style.display = "block";
        this.beforeIndex.push(index);
        if (this.bigClass === "") {
          this.classopenOrName(this.bigClassSousuo[index]);
        } else {
          this.Classopen(this.bigClass[index].id);
        }
        if (this.bigClassSousuo == "") {
          this.start = this.beforeIndex.length;
        } else {
          if (this.bigClassSousuo[index].status == 'true') {
            this.start = 1;
          } else {
            this.start = this.beforeIndex.length;
          }
        }
      }
    },
    // 点击关闭图片取消选择的分类
    close(index) {
      var unSelected = document.querySelectorAll(".unSelected");
      var img = document.querySelectorAll(".listClose");
      img[index].style.display = "none";
      unSelected[index].style.border =
        0.1875 + "rem /* 3px -> 0.1875rem */ solid #fff";
      // 删除指定的大类
      for (let i = 0; i < this.classdata.length; i++) {
        if (this.bigClass === "") {
          if (this.classdata[i].id == this.bigClassSousuo[index].id) {
            this.classdata.splice(i, 1);
          }
        } else {
          if (this.classdata[i].id == this.bigClass[index].id) {
            this.classdata.splice(i, 1);
          }
        }
      }
      let indexNum = this.beforeIndex.findIndex((el) => el == index);
      this.beforeIndex.splice(indexNum, 1);
    },
    fn1(data) {
      if (this.start == data) {
        this.start = 0;
      } else {
        this.start = data;
      }
    },
    fn2(index) {
      if (this.tubiao == index) {
        this.tubiao = 0;
      } else {
        this.tubiao = index;
      }
    },
    async classopenOrName(data) {
      this.state06 = true;
      //根据大类id和搜索的关键字返回群组和小项
      const res = await transousuoClassGroupApi({
        key: sessionStorage.getItem("key"),
        category_id: data.id,
        content: this.inputText,
      });
      await sessionStorage.setItem("key", res.data.key);
      //群组数据的排序（高亮在前）
      for (let i = 0; i < res.data.category.group.length; i++) {
        if (res.data.category.group[i].status == "true") {
          res.data.category.group.unshift(res.data.category.group[i]);
          res.data.category.group.splice(i + 1, 1);
        }
      }
      // 小项数据的排序（高亮在前）
      for (var i = 0; i < res.data.category.group.length; i++) {
        if (res.data.category.group[i].term !== null) {
          for (var f = 0; f < res.data.category.group[i].term.length; f++) {
            if (res.data.category.group[i].term[f].status === "true") {
              let one = res.data.category.group[i].term.unshift(
                res.data.category.group[i].term[f]
              );
              let two = res.data.category.group[i].term.splice(f + 1, 1);
            }
          }
        }
      }
      //大类数据的排序（高亮在前）
      if (data.status == "true") {
        res.data.category.status = true;
        this.classdata.splice(0, 0, res.data.category);
      } else {
        this.classdata.push(res.data.category); //大类的介绍数据
      }
      this.state06 = false;
    },
    async Classopen(data) {
      //根据大类id返回群组和小项
      this.state06 = true;
      const res = await tranClassGroupApi({
        key: sessionStorage.getItem("key"),
        category_id: data,
      });
      this.classdata.push(res.data.general); //大类的介绍数据
      await sessionStorage.setItem("key", res.data.key);
      this.state06 = false;
    },
    async Text(data) {
      this.classdata = [];
      this.bigClass = "";
      this.bigClassSousuo = ""
      this.inputText = data;
      this.state05 = true;
      if (data !== "") {
        this.bigClass = "";
        // 查询大类请求
        let key = sessionStorage.getItem("key");
        const res = await transousuoClassApi({
          key: key,
          content: data,
        });
        if (res.msg == "success") {
          this.bigClassSousuo = res.data.category; //带有查询成功状态为true的大类
          this.inputTextClass.length = 0; //清除数组中的元素
          for (let i = 0; i < res.data.category.length; i++) {
            if (res.data.category[i].status == "true") {
              this.inputTextClass.push(res.data.category[i].id); //将带有状态值为true的大类放入一个新数组里
            }
          }
          this.beforeIndex = [];
          await sessionStorage.setItem("key", res.data.key);
          this.state05 = false;
        }
      } else {
        this.beforeIndex = [];
        this.bigClassSousuo = ""; //将搜索选择的大类清空
        this.classdata = [] //将选择的大类清空
        this.bigClass = "";
        await this.creat();
      }
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  border: none;
  margin-top: 0.625rem /* 10px -> 0.625rem */;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  background-color: #4d89f1;
}
.unSelectedCopy {
  border-color: #ff7e00;
}

.tradIndex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .findConten {
    width: 76.875rem /* 1230px -> 76.875rem */;
    padding-bottom: 4.6875rem /* 75px -> 4.6875rem */;
    padding-top: 2.125rem /* 34px -> 2.125rem */;
    box-sizing: border-box;
    .contentLeft {
      float: left;
      width: 56.875rem /* 910px -> 56.875rem */;
      .classificationList {
        width: 100%;
        padding-top: 2.5rem /* 40px -> 2.5rem */;
        padding-bottom: 2.5rem /* 40px -> 2.5rem */;
        box-sizing: border-box;
        margin-bottom: 1.25rem /* 20px -> 1.25rem */;
        border-bottom: 1.25rem /* 20px -> 1.25rem */ solid #f5f5f5;
        display: flex;
        flex-direction: column;
        .listTitle {
          color: #4b505e;
          font-size: 1rem /* 16px -> 1rem */;
          margin-left: 0.4375rem /* 7px -> 0.4375rem */;
        }
        .listContent {
          display: flex;
          flex-wrap: wrap;
          .option {
            min-width: 5.625rem /* 90px -> 5.625rem */;
            margin: 0.3125rem /* 5px -> 0.3125rem */;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .unSelected {
              min-width: 5.625rem /* 90px -> 5.625rem */;
              height: 1.9375rem /* 31px -> 1.9375rem */;
              border: 0.1875rem /* 3px -> 0.1875rem */ solid #fff;
              box-sizing: border-box;
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #666666;
              text-align: center;
              line-height: 1.5625rem; /* 25px -> 1.5625rem */
              cursor: pointer;
            }
            .listClose01 {
              width: 0.6875rem /* 11px -> 0.6875rem */;
              height: 0.625rem /* 10px -> 0.625rem */;
              .listClose {
                width: 100%;
                height: 100%;
                background: #2f3ab9;
                position: relative;
                cursor: pointer;
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
      .contentLeftHeader {
        widows: 100%;
        height: 3.125rem /* 50px -> 3.125rem */;
        background-color: #ff7e00;
        padding-left: 1.875rem /* 30px -> 1.875rem */;
        font-size: 1rem /* 16px -> 1rem */;
        color: #fff;
        line-height: 3.125rem /* 50px -> 3.125rem */;
      }
      .contentLeftTitle {
        width: 100%;

        .contentLeftTitleHeader {
          width: 100%;
          height: 4rem /* 64px -> 4rem */;
          border-bottom: 0.0625rem /* 1px -> 0.0625rem */ solid #e1e1e1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .contentLeftTitleHeaderRight {
            width: 1.1875rem /* 19px -> 1.1875rem */;
            height: 0.625rem /* 10px -> 0.625rem */;
            transition-duration: 0.25s;
          }
          .contentLeftTitleHeaderLeft {
            display: flex;
            align-items: center;
            height: 100%;
            color: #666666;
            .lan {
              width: 0.1875rem /* 3px -> 0.1875rem */;
              height: 1.4375rem /* 23px -> 1.4375rem */;
              background-color: #4d89f1;
              margin-right: 1.25rem /* 20px -> 1.25rem */;
            }
            .word01 {
              font-size: 1rem /* 16px -> 1rem */;
              margin-right: 1.5625rem /* 25px -> 1.5625rem */;
            }
            
          }
          .contentLeftTitleHeaderLeft:hover {
              color: #4d89f1;
            }
        }
        .contentLeftTitleContent {
          display: block;
          overflow: hidden;
          transition-duration: 0.25s;
          margin-top: 2.1875rem /* 35px -> 2.1875rem */;
          margin-bottom: 2.1875rem /* 35px -> 2.1875rem */;
          margin-left: 1.75rem /* 28px -> 1.75rem */;
          .MsoNormal {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #777777;
            line-height: 1.875rem /* 30px -> 1.875rem */;
          }
        }
        .findlei {
          height: 3.75rem /* 60px -> 3.75rem */;
          display: flex;
          align-items: center;
          .icon01 {
            width: 1.5rem /* 24px -> 1.5rem */;
            height: 1.4375rem /* 23px -> 1.4375rem */;
            margin-right: 0.9375rem /* 15px -> 0.9375rem */;
            background-image: url("../imger/tranClassGroup/open.png");
            background-size: 100% 100%;
            cursor: pointer;
          }
          .icon02 {
            width: 1.5rem /* 24px -> 1.5rem */;
            height: 1.4375rem /* 23px -> 1.4375rem */;
            margin-right: 0.9375rem /* 15px -> 0.9375rem */;
            background-image: url("../imger/tranClassGroup/unopen.png");
            background-size: 100% 100%;
            cursor: pointer;
          }
          .findleititle {
            font-size: 1rem /* 16px -> 1rem */;
            color: #333333;
            cursor: pointer;
          }
          .findleititle:hover {
            color: #4d89f1;
          }
        }
        .findcontent {
          width: 54.5625rem /* 873px -> 54.5625rem */;
          padding: 0.625rem /* 10px -> 0.625rem */;
          box-sizing: border-box;
          margin-bottom: 1.25rem /* 20px -> 1.25rem */;
          border-radius: 1.25rem /* 20px -> 1.25rem */;
          border: 0.0625rem /* 1px -> 0.0625rem */ solid #e1e1e1;
          .findcontentBox {
            height: 21.875rem /* 350px -> 21.875rem */;
            width: 100%;
            margin: 0.625rem /* 10px -> 0.625rem */;
            overflow-y: auto;
            overflow-x: hidden;
            .first {
              display: inline-block;
              width: 25rem /* 400px -> 25rem */;
              height: 1.75rem /* 28px -> 1.75rem */;
              font-size: 1rem /* 16px -> 1rem */;
              color: #666666;
              line-height: 1.75rem /* 28px -> 1.75rem */;
            }
          }
          .remarks {
            width: 100%;
            padding: 0.75rem /* 12px -> 0.75rem */;
            box-sizing: border-box;
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #666666;
            line-height: 1.625rem /* 26px -> 1.625rem */;
          }
        }
      }
    }
    .contentRight {
      float: right;
      width: 14.5rem /* 232px -> 14.5rem */;
      .header {
        width: 100%;
        height: 3.125rem /* 50px -> 3.125rem */;
        background-color: #ffc41d;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #f74339;
        text-align: center;
        line-height: 3.125rem /* 50px -> 3.125rem */;
      }
      .box {
        height: 20.875rem /* 334px -> 20.875rem */;
        width: 100%;
        margin-top: 0.875rem /* 14px -> 0.875rem */;
        display: flex;
        flex-direction: column;
        .picture {
          width: 100%;
          height: 14.625rem /* 234px -> 14.625rem */;
        }
        .onpicture {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .boxtitle {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #666666;
            display: flex;
            justify-content: space-around;
          }
          .boxprice {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .price {
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #e51f26;
            }
            .anniu {
              width: 4.125rem /* 66px -> 4.125rem */;
              height: 1.5625rem /* 25px -> 1.5625rem */;
              background-color: #4d89f1;
              border-radius: 0.78125rem /* 12.5px -> 0.78125rem */;
              font-size: 0.75rem /* 12px -> 0.75rem */;
              color: #fff;
              text-align: center;
              line-height: 1.5625rem /* 25px -> 1.5625rem */;
            }
          }
        }
      }
    }
  }
}
</style>