import { render, staticRenderFns } from "./sblb.vue?vue&type=template&id=20a824d3&scoped=true&"
import script from "./sblb.vue?vue&type=script&lang=js&"
export * from "./sblb.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./sblb.vue?vue&type=style&index=0&id=20a824d3&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "20a824d3",
  "3fd1a23f"
  
)

export default component.exports